import _kindOf from "kind-of";
var exports = {};
var typeOf = _kindOf; // accessor descriptor properties

var accessor = {
  get: "function",
  set: "function",
  configurable: "boolean",
  enumerable: "boolean"
};

function isAccessorDescriptor(obj, prop) {
  if (typeof prop === "string") {
    var val = Object.getOwnPropertyDescriptor(obj, prop);
    return typeof val !== "undefined";
  }

  if (typeOf(obj) !== "object") {
    return false;
  }

  if (has(obj, "value") || has(obj, "writable")) {
    return false;
  }

  if (!has(obj, "get") || typeof obj.get !== "function") {
    return false;
  } // tldr: it's valid to have "set" be undefined
  // "set" might be undefined if `Object.getOwnPropertyDescriptor`
  // was used to get the value, and only `get` was defined by the user


  if (has(obj, "set") && typeof obj[key] !== "function" && typeof obj[key] !== "undefined") {
    return false;
  }

  for (var key in obj) {
    if (!accessor.hasOwnProperty(key)) {
      continue;
    }

    if (typeOf(obj[key]) === accessor[key]) {
      continue;
    }

    if (typeof obj[key] !== "undefined") {
      return false;
    }
  }

  return true;
}

function has(obj, key) {
  return {}.hasOwnProperty.call(obj, key);
}
/**
 * Expose `isAccessorDescriptor`
 */


exports = isAccessorDescriptor;
export default exports;